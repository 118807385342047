import React from 'react'
import { replace } from 'gatsby'

import Layout from '../components/Layout'

import './404.scss'

const NoMatch = ({ location }) => {
  
  if (location && location.pathname) {
    if (location.pathname.includes('contact')) {
      replace('/Contact')
    }
    if (location.pathname.includes('form')) {
      replace('/Form')
    }
    if (location.pathname.includes('me')) {
      replace('/Me')
    }
    if (location.pathname.includes('services')) {
      replace('/Services')
    }
    if (location.pathname.includes('testimonials')) {
      replace('/Testimonials')
    }
  }
  
  return <Layout location={location} description='Page Not Found' />
}

export default NoMatch